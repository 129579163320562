import React from "react";
import Navbar from '../components/Navbar';
import {Background, Content, HomeContainer, YellowText} from '../css/Home.style';
import Footer from "../components/Footer";
import {useNavigate} from "react-router-dom"
import {MemberCard, MemberImage, MemberInfo, MemberName, MemberRole, TeamContainer, TitleTeam} from "../css/Team.styles"
// @ts-ignore
import arrow from "../../assets/arrow.png"; // Ensure this path is correct
// @ts-ignore
import marius from "../../assets/marius.png"
// @ts-ignore
import killian from "../../assets/kylian.png"
// @ts-ignore
import mathis from "../../assets/mathis.png"
// @ts-ignore
import yani from "../../assets/yani.png"
// @ts-ignore
import theophile from "../../assets/theophile.png"
// @ts-ignore
import gurvan from "../../assets/gurvan.png"
// @ts-ignore
import ilona from "../../assets/ilona.png"
import LinkedinIcon from '@mui/icons-material/LinkedIn';
// @ts-ignore
import { useIntl } from 'react-intl';

const Team: React.FC = () => {
    const navigate = useNavigate();
    const intl = useIntl();
    const { formatMessage } = intl;

    const teamMembers = [
        {
            name: "Marius Delahay",
            role: formatMessage({ id: 'team.marius.job' }),
            image: marius,
            linkedin: "https://www.linkedin.com/in/delahay-marius/",
        },
        {
            name: "Ilona Boizard",
            role: formatMessage({ id: 'team.ilona.job' }),
            image: ilona,
            linkedin: "https://www.linkedin.com/in/ilona-boizard/",
        },
        {
            name: "Killian Mannarelli",
            role: formatMessage({ id: 'team.killian.job' }),
            image: killian,
            linkedin: "https://www.linkedin.com/in/killian-mannarelli/",
        },
        {
            name: "Théophile Laborde",
            role: formatMessage({ id: 'team.theophile.job' }),
            image: theophile,
            linkedin: "https://www.linkedin.com/in/théophile-laborde-b7067a324/",
        },
        {
            name: "Yani Madebos",
            role: formatMessage({ id: 'team.yani.job' }),
            image: yani,
            linkedin: "https://www.linkedin.com/in/yani-madebos/",
        },
        {
            name: "Mathis Tamarelle",
            role: formatMessage({ id: 'team.mathis.job' }),
            image: mathis,
            linkedin: "https://www.linkedin.com/in/mathis-tamarelle/",
        },
        {
            name: "Gurvan Dumarchat",
            role: formatMessage({ id: 'team.gurvan.job' }),
            image: gurvan,
            linkedin: "https://www.linkedin.com/in/gurvan-dumarchat-957434303/",
        },
    ];

    const handleBackClick = () => {
        navigate('/');
    };

    const handleLinkedinLink = (url: string) => {
        window.open(url, "_blank");
    };

    return (
        <HomeContainer>
            <Background/>
            <Navbar selectedNavItem="Team"/>
            <Content style={{overflowX: "hidden"}}>
                <img src={arrow} alt="arrow" style={{
                    left: "15%",
                    position: "absolute",
                    top: "220px",
                    cursor: "pointer",
                    zIndex: 1,
                    width: "56px"
                }} onClick={handleBackClick}/>
                <TitleTeam>OUR TEAM<YellowText>.</YellowText></TitleTeam>
                <TeamContainer>
                    {teamMembers.map((member, index) => (
                        <MemberCard key={index}>
                            <MemberImage src={member.image} alt={member.name}/>
                            <MemberInfo>
                                <MemberName>{member.name}</MemberName>
                                <MemberRole>{member.role}</MemberRole>
                                <LinkedinIcon sx={{
                                    width: "20px",
                                    height: "20px",
                                    cursor: "pointer",
                                    left: "85%",
                                    position: "relative"
                                }} onClick={() => handleLinkedinLink(member.linkedin)}/>
                            </MemberInfo>
                        </MemberCard>
                    ))}
                </TeamContainer>
                <div style={{top: "1800px", position: "relative", width: "100%"}}>
                    <Footer/>
                </div>
            </Content>
        </HomeContainer>
    );
};

export default Team;
